.carousel{
    width: 95%;
    margin: 5% auto  ;
    height: 100%;
    z-index: 0;
  
}
.carousel .makeStyles-indicators-4{
    margin-top: 12px;
    z-index: 0;
}

@media only screen and (min-width:1020px) {
    .carousel{
        width: 70%;
    }
}
